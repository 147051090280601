<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("SETTINGS.USER.TEAM.TITLE1") }}
          </strong>
          <br />
          <i class="test-muted">
            {{ $t("SETTINGS.USER.TEAM.TITLE6") }}
          </i>
        </h5>
      </b-col>

      <!-- firstname -->
      <b-col lg="5" md="5" sm="5" cols="12">
        <v-text-field
          v-model="firstname"
          :label="$t('SETTINGS.USER.PERSONAL.FIELDS.FNAME')"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- lastname -->
      <b-col lg="5" md="5" sm="5" cols="12">
        <v-text-field
          v-model="lastname"
          :label="$t('SETTINGS.USER.PERSONAL.FIELDS.LNAME')"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- EMAIL -->
      <b-col lg="5" md="5" sm="5" cols="12">
        <v-text-field
          v-model="email"
          :label="$t('SETTINGS.USER.PERSONAL.FIELDS.EMAIL')"
          :rules="validations.emailRequired"
          required
          type="email"
          outlined
          dense
        />
      </b-col>

      <!-- PHONE -->
      <b-col lg="5" md="5" sm="5" cols="12">
        <v-text-field
          v-model="phone"
          :label="$t('SETTINGS.USER.PERSONAL.FIELDS.PHONE')"
          :rules="validations.required"
          required
          type="number"
          outlined
          dense
        />
      </b-col>

      <!-- Permissions to manage -->
      <b-col
        lg="12"
        md="12"
        sm="12"
        cols="12"
        v-if="currentUser.user.companyId"
      >
        <spand class="required-label title">
          {{ $t("SETTINGS.USER.TEAM.TITLE5") }}
        </spand>
      </b-col>

      <!--TYPE -->
      <b-col cols="12" v-if="currentUser.user.companyId">
        <v-radio-group v-model="type" row>
          <v-radio :label="$t('SETTINGS.USER.TEAM.TYPE_USER_1')" :value="1" />
          <v-radio
            :label="auxLabelType"
            :value="0"
            @click="validateListCampus"
            v-if="auxLabelType"
          />
        </v-radio-group>
      </b-col>

      <!-- MODAL NOT CAMPUS -->
      <b-col lg="5" md="5" sm="5" cols="12" v-if="type === 0">
        <v-dialog v-model="isShowDialogCampus" max-width="350">
          <v-card class="pt-3">
            <v-card-title class="headline">
              {{ $t("SETTINGS.USER.TEAM.NOT_CAMPUS") }}
            </v-card-title>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="red darken-1" text @click="type = 1">
                {{ $t("GENERAL.CANCEL") }}
              </v-btn>

              <v-btn color="primary darken-1" text @click="goToNewCampus">
                {{ $t("SETTINGS.USER.TEAM.CREATE_CAMPUS") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- SELECT CAMPUS -->
        <v-select
          v-model="campusId"
          :items="campuses"
          :label="$t('SETTINGS.USER.TEAM.SELECT_CAMPUS')"
          item-text="name"
          item-value="campusId"
          dense
          outlined
          v-if="!isShowDialogCampus"
        />
      </b-col>
    </b-row>

    <!-- Permissions  -->
    <v-row justify="space-around">
      <b-col lg="12" md="12" sm="12" cols="12">
        <spand class="required-label title">
          {{ $t("SETTINGS.USER.TEAM.TITLE3") }}
        </spand>
      </b-col>
      <b-col md="12" sm="12" cols="12">
        <v-checkbox
          v-model="allPermissions"
          class="align-label"
          :label="$t('SETTINGS.USER.TEAM.ROLES.OPT1')"
          @click="getAll"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="6"
        cols="12"
        v-for="(item, i) in rolItems"
        :key="i"
        class="cold-items"
      >
        <v-checkbox
          v-model="item.value"
          class="align-label m-0"
          :label="item.label"
        />
      </b-col>
    </v-row>
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-btn
          depressed
          color="primary"
          class="ml-3"
          :loading="invite_member"
          @click="onSubmit"
        >
          {{ $t("SETTINGS.USER.TEAM.BTN.INVITE") }}
        </v-btn>
      </b-col>
    </b-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import { REGISTER_TEAM } from "@/core/services/store/auth.module";
import Rules from "@/core/services/utils/validations";

const SCHOOL = 1;

export default {
  data() {
    return {
      valid: true,
      isShowDialogCampus: false,
      invite_member: false,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      type: null,
      campusId: null,
      auxLabelType: null,
      allPermissions: false,
      rolItems: [
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT2"),
          value: false,
        },
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT4"),
          value: false,
        },
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT3"),
          value: false,
        },
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT5"),
          value: false,
        },
      ],
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  mounted() {
    const { companyTypeId } = this.company;
    this.auxLabelType =
      companyTypeId === SCHOOL
        ? this.$t("SETTINGS.USER.TEAM.TYPE_USER_2_SCHOOL")
        : this.$t("SETTINGS.USER.TEAM.TYPE_USER_2_HOMESTAY");
    this.rolItems[0].label =
      companyTypeId === SCHOOL
        ? this.$t("SETTINGS.USER.TEAM.ROLES.OPT2")
        : this.$t("SETTINGS.USER.TEAM.ROLES.OPT6");
  },
  methods: {
    /**
     * set all permissions
     * @method getAll
     */
    getAll() {
      const flag = this.allPermissions ? true : false;
      this.rolItems.map(rol => {
        rol.value = flag;
        return rol;
      });
    },

    /**
     * validate list campus
     * @method validateListCampus
     * @returns 'isShowDialogCampus'
     */
    validateListCampus() {
      if (!this.campuses[0].campusId) {
        this.isShowDialogCampus = true;
      }
    },

    /**
     * go to new campus
     * @method goToNewCampus
     */
    goToNewCampus() {
      this.$router.push({ name: "campus-new" });
    },

    /**
     * Create new user for team
     * @method onSubmit
     * @returns 'users' whit new data
     */
    onSubmit() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.invite_member = true;
        const scopesArray = [];
        this.rolItems.forEach((rol, index) => {
          if (rol.value) {
            scopesArray.push(index + 1);
          }
        });

        let id_campus, type;
        const { user } = this.currentUser;

        if (user.companyId) {
          type = this.type;
          id_campus = type === 1 ? user.campusId : this.campusId;
        } else {
          type = 0;
          id_campus = user.campusId;
        }

        const data = {
          name: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          type: type,
          campusId: id_campus,
          permissions: scopesArray.join(),
        };

        this.$store
          .dispatch(REGISTER_TEAM, data)
          .then(() => {
            this.$refs.form.reset();
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
            this.invite_member = false;
          })
          .catch(() => {
            this.invite_member = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters(["campuses", "currentUser", "campus", "company"]),
  },
};
</script>

<style scoped>
.align-label .v-label {
  top: 3px !important;
}

.title {
  font-size: 1.23rem;
  font-weight: 100 !important;
}

.cold-items {
  padding-top: 0 !important;
}
</style>
