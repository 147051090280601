<template>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("SETTINGS.USER.TEAM.TITLE2") }}
          </strong>
        </h5>
      </b-col>
      <!-- LIST USERS -->
      <b-col
        lg="4"
        md="6"
        sm="6"
        cols="12"
        v-for="(item, i) in userList"
        :key="i"
      >
        <v-card color="white">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-avatar class="ma-3" size="80" tile>
              <img
                :src="`${item.photo.url}?h=100&w=100&fit=crop`"
                v-if="item.photo"
              />
              <img :src="picture" v-else />
            </v-avatar>
            <div class="w-100">
              <div class="container-dropdown">
                <b-dropdown
                  class="font-weight-bold font-size-3"
                  right
                  variant="link"
                  no-caret
                  toggle-class="btn-hover-light-primary btn-sm btn-icon btn btn-clean custom-v-dropdown"
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-ver"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <!-- EDIT PERMISSION  -->
                    <b-dropdown-text tag="div" class="navi-item">
                      <a
                        href="javascript:void(0)"
                        class="navi-link"
                        @click="editUser(item.id)"
                      >
                        <span class="navi-icon">
                          <font-awesome-icon icon="edit" />
                        </span>
                        <span class="navi-text">
                          {{ $t("GENERAL.EDIT") }}
                        </span>
                      </a>
                    </b-dropdown-text>
                    <!-- SEND INVITATION  -->
                    <b-dropdown-text tag="div" class="navi-item">
                      <a
                        href="javascript:void(0)"
                        class="navi-link"
                        @click="reSend(item.id, item.email)"
                      >
                        <span class="navi-icon">
                          <font-awesome-icon icon="envelope" />
                        </span>
                        <span class="navi-text">
                          {{ $t("SETTINGS.USER.TEAM.BTN.RE_SEND") }}
                        </span>
                      </a>
                    </b-dropdown-text>
                    <!-- DELETE USER  -->
                    <b-dropdown-text tag="div" class="navi-item">
                      <a
                        href="javascript:void(0)"
                        class="navi-link"
                        @click="confirmDeleteUser(item)"
                      >
                        <span class="navi-icon">
                          <font-awesome-icon icon="trash-alt" />
                        </span>
                        <span class="navi-text">
                          {{ $t("GENERAL.DELETE") }}
                        </span>
                      </a>
                    </b-dropdown-text>
                  </div>
                </b-dropdown>
              </div>
              <v-card-title v-text="item.name" />
              <v-card-subtitle v-text="item.email" />
            </div>
          </div>
        </v-card>
      </b-col>
    </b-row>

    <!-- MODAL EDIT PERMISSIONS -->
    <v-dialog v-model="dialog" scrollable max-width="600px">
      <v-card>
        <v-card-title>{{ dataEditUser.name }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          {{ $t("SETTINGS.USER.TEAM.TITLE3") }}
          <div v-for="(item, i) in rolItems" :key="i">
            <v-checkbox v-model="item.value" class="mx-2" :label="item.label" />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-content-end">
          <v-btn depressed color="grey" class="ml-3" @click="dialog = false">
            {{ $t("GENERAL.CANCEL") }}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="ml-3"
            :loading="save_loading"
            @click="updateRol(dataEditUser.id)"
          >
            {{ $t("GENERAL.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- MODAL DELETE USER -->
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card class="pt-3">
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="deleteUser(dataEditUser.id)"
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  FORGOT_PASSWORD,
  GET_DELETE_USER,
  UPDATE_USER_ROL,
  GET_ALL_USERS,
} from "@/core/services/store/auth.module";
import Rules from "@/core/services/utils/validations";

const GENERAL = 0;
const PRODUCTS = 1;
const BOOKINGS = 2;
const FINANCIAL = 3;

const GENERAL_ROL = 1;
const PRODUCTS_ROL = 2;
const BOOKINGS_ROL = 3;
const FINANCIAL_ROL = 4;

export default {
  data() {
    return {
      dialog: false,
      save_loading: false,
      isShowDialogDelete: false,
      dataEditUser: {
        id: null,
        name: null,
        companyId: null,
      },
      companyId: null,
      rolItems: [
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT2"),
          value: false,
        },
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT4"),
          value: false,
        },
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT3"),
          value: false,
        },
        {
          label: this.$t("SETTINGS.USER.TEAM.ROLES.OPT5"),
          value: false,
        },
      ],
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * Delete user by id
     * @method deleteUser
     * @returns 'users' whit new data
     */
    deleteUser(id) {
      this.$store.dispatch(GET_DELETE_USER, id).then(({ data }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
        this.isShowDialogDelete = false;
      });
    },

    /**
     * Confirm delete user
     * @method confirmDeleteUser
     * @param user with data of current user
     * @returns 'dataEditUser' with data user
     */
    confirmDeleteUser(user) {
      this.dataEditUser.name = user.name;
      this.dataEditUser.id = user.id;
      this.dataEditUser.companyId = user.companyId;
      this.isShowDialogDelete = true;
    },

    /**
     * Re send invitation for user id
     * @method reSend
     * @param user_id
     * @param data
     */
    reSend(user_id, data) {
      const { user } = this.currentUser;
      const json = {
        email: data,
        parent_user_id: user.id,
        new: user_id,
      };
      this.$store.dispatch(FORGOT_PASSWORD, json).then(({ data }) => {
        this.$bvToast.toast(data.message, {
          title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
      });
    },

    /**
     * Edit permission of the user
     * @method editUser
     * @param id
     */
    editUser(id) {
      this.rolItems[GENERAL].value = false;
      this.rolItems[BOOKINGS].value = false;
      this.rolItems[PRODUCTS].value = false;
      this.rolItems[FINANCIAL].value = false;
      const currentUser = this.userList.filter(user => user.id === id);
      const [userData] = currentUser;

      userData.user_roles.forEach(rol => {
        if (rol.userRoleTypeId === GENERAL_ROL) {
          this.rolItems[GENERAL].value = true;
        } else if (rol.userRoleTypeId === PRODUCTS_ROL) {
          this.rolItems[PRODUCTS].value = true;
        } else if (rol.userRoleTypeId === BOOKINGS_ROL) {
          this.rolItems[BOOKINGS].value = true;
        } else if (rol.userRoleTypeId === FINANCIAL_ROL) {
          this.rolItems[FINANCIAL].value = true;
        }
      });

      this.dataEditUser.name = userData.name;
      this.dataEditUser.id = userData.id;
      this.dataEditUser.companyId = userData.companyId;
      this.dialog = true;
    },

    /**
     * Update permission of the user
     * @method updateRol
     * @param id
     */
    updateRol(id) {
      this.save_loading = true;
      const currentUser = this.userList.filter(user => user.id === id);
      const [userData] = currentUser;
      const scopesArray = [];
      this.rolItems.forEach((rol, index) => {
        if (rol.value) {
          scopesArray.push(index + 1);
        }
      });

      let auxPhoto = userData.photo ? userData.photo.fileId : null;

      const data = {
        ...userData,
        photoId: auxPhoto,
        scopes: scopesArray.join(),
      };

      this.$store.dispatch(UPDATE_USER_ROL, data).then(() => {
        this.save_loading = false;
        this.dialog = false;
      });
    },
  },
  mounted() {
    if (!this.userList.length) {
      this.$store.dispatch(GET_ALL_USERS);
    }
  },
  computed: {
    ...mapGetters(["campuses", "userList", "currentUser", "campus"]),
    picture() {
      return `${process.env.VUE_APP_CDN_PATH}images/default/blank.png`;
    },
    getError() {
      return this.error;
    },
  },
};
</script>
