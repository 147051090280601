import { render, staticRenderFns } from "./FormTeams.vue?vue&type=template&id=663bc9c3&scoped=true&"
import script from "./FormTeams.vue?vue&type=script&lang=js&"
export * from "./FormTeams.vue?vue&type=script&lang=js&"
import style0 from "./FormTeams.vue?vue&type=style&index=0&id=663bc9c3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663bc9c3",
  null
  
)

export default component.exports