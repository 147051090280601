<template>
  <div class="team-settings-component">
    <div class="card-custom-bussiness">
      <FormTeams />
    </div>
    <div class="card-custom-bussiness">
      <UserList />
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FormTeams from "./components/FormTeams";
import UserList from "./components/UserList";

export default {
  components: {
    FormTeams,
    UserList,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SETTINGS.USER.MAIN.TEAM") },
    ]);
  },
};
</script>
